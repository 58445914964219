import React from 'react';
import ResponsiveAppBar from './appBarr.js';
import Stack from '@mui/material/Stack';
import photo from './header.jpg'
import { Button,Typography, Box } from '@mui/material';
import { CardMedia } from '@mui/material';
import img from './header.jpg'
function Header(props) {

    return (
        <Box
        sx={{
            backgroundImage: "url('https://i.imgur.com/XOCqJH9.jpeg')",
            backgroundRepeat: "no-repeat",
            height: '585px',
            width: '100%',
            backgroundSize: 'contain',
            backgroundAttachment: 'fixed',
            overflow: 'hidden'
          }}
        >
            {/* <ResponsiveAppBar /> */}
         
            {/* <CardMedia 
             component="img"
             height="540"
            width='100%'
             image={photo}
            /> */}
            <Stack  sx={{ width: '60%', margin: '0 auto', height: '100%' }} direction="column"  justifyContent="center" spacing={2}>
           
            <Button href="#gimel" variant="contained">מחשבון גימלים</Button>
            <Button href="#hot" variant="contained">מחשבון עומס חום</Button>
            <Button href="#cold" variant="contained">מחשבון עומס קור</Button>
            <Button href="#times" variant="contained">זמני פעילות מרפ"אות</Button>
            <Button href="#ana"variant="contained">אנמנזה</Button>
            </Stack>

            
           
        </Box>
    );
}

export default Header;